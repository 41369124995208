/* .project-page {
  padding: 8px 18px;
} */

.project-page .drafts-box {
  height: 28px;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #ffffff;
  /* margin: 12px 0; */
}

.project-page .project-card {
  background-color: #13202d !important;
  box-shadow: none !important;
}

.chip-level {
  /* margin-top: 15px !important; */
  /* margin-right: 8px; */
  width: 34px !important;
  height: 20px !important;
  border-radius: 20px !important;
}

.chip-level-box {
  color: #ffffff !important;
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: var(--font-base);
}

.relative-top {
  position: relative;
  padding-top: 10px;
}

.color-white {
  color: #ffffff !important;
}

.d-flex {
  display: flex;
}

.font-size-14 {
  font-size: var(--font-base);
}

.grid-color {
  color: #ffffff !important;
  margin-top: 8px;
  margin-right: 16px;
}

.grid-radius {
  background-color: #002942 !important;
  border-radius: 16px;
  margin: 8px;
  padding: 16px;
}

.img {
  width: 238px;
  height: 330px;
}

.draftsProducts-slider .slick-list,
.submittedProducts-slider .slick-list {
  margin: 0 -12px;
}

.draftsProducts-slider .slick-slide,
.submittedProducts-slider .slick-slide {
  padding: 0 8px;
}

/* common css used for edit btn icon on card -start*/
.rc-asset-card-save-button.MuiIconButton-root {
  z-index: 1;
  border-radius: 12px;
  margin-bottom: 6px;
  text-transform: none;
  background-color: var(--blue-100);
  position: absolute;
  right: 6px;
  top: 6px;
}

.rc-asset-card-save-button.MuiIconButton-root:hover {
  background-color: var(--blue-500);
}

.coming-soon-my-project p {
  width: 490px;
  color: var(--white-color);
  font-size: 57px;
  line-height: 64px;
  letter-spacing: -0.25px;
  margin-left: 69px;
}

/* end */

@media only screen and (max-width: 767px) {
  .project-page .small-heading {
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
  }
}