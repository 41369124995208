/*================================= 
Root Variables
=================================*/
:root {
  --body-fontFamily: "Rubik", sans-serif;
  --white-color: #ffffff;
  --black-color: #000000;
  --blue-900: #032744;
  --blue-800: #053863;
  --blue-700: #07487f;
  --blue-600: #06487e;
  --blue-500: #1771bd;
  --blue-400: #205a7e;
  --blue-300: #7bb9ed;
  --blue-200: #aedaff;
  --blue-100: #6a91a9;
  --blue-50: #f1f9ff;
  /* --blue-50: #83a4b8; */
  --blueWarmGray-500: #78716c;
  /* --blueGray-900: #0f172a; */
  --blueGray-900: #05131b;
  --blueGray-700: #334155;
  --blueGray-600: #475569;
  --blueGray-500: #64748b;
  --blueGray-400: #94a3b8;
  --blueGray-300: #cbd5e1;
  --blueGray-200: #e2e8f0;
  --blueGray-100: #f1f5f9;
  --blueGray-50: #f8fafc;
  --yellow-900: #ad6941;
  --yellow-800: #998c61;
  --yellow-700: #aea06e;
  --yellow-500: #dcae92;
  --yellow-200: #fde0cf;
  --yellow-100: #e9deb9;
  --yellow-50: #e9deb9;
  --yellow-60: #ffede2;
  --red-600: #dc2626;
  --red-500: #ef4444;
  --green-500: #10b981;
  --green-400: #34d399;

  --Palette-Emerald-400: #34d399;
  --lite-100: #eff6ff;
  --Palette-Teal-100: #c5e4ff;
  --golden-gradient: linear-gradient(90deg,
      #b37a56 0%,
      #fbcba7 32.29%,
      #c38a79 71.35%,
      #945b3b 95.31%);

  /* Font size variables*/
  --font-10: 10px;
  --font-12: 12px;
  --font-13: 13px;
  --font-base: 14px;
  --font-16: 16px;
  --font-18: 18px;
  --font-24: 24px;
  --font-26: 26px;
  --font-28: 28px;
  --font-32: 32px;
}

/*================================= 
Base
=================================*/
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--body-fontFamily);
  font-size: var(--font-base);
  color: var(--white-color);
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.3;
  /* background: var(--blueGray-900); */
  background: #010509;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  /* overflow-y: auto !important;
   padding-right: 0 !important; */
}

body .MuiChip-root,
body p.MuiTypography-root,
body a,
body div {
  font-family: var(--body-fontFamily);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

p.MuiTypography-root {
  line-height: 1.4;
  font-size: var(--font-base);
}

h1.MuiTypography-root,
h2.MuiTypography-root,
h3.MuiTypography-root,
h4.MuiTypography-root,
h5.MuiTypography-root,
h6.MuiTypography-root {
  line-height: 1.2;
  font-family: var(--body-fontFamily);
}

a {
  text-decoration: none;
  color: var(--white-color);
}

/* Set Container */
.container {
  width: 100%;
  max-width: 1440px;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: auto;
  margin-right: auto;
}

.text-underline {
  text-decoration: underline;
}

.text-golden.text-underline {
  position: relative;
  padding-bottom: 1px;
}

.text-golden.text-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--golden-gradient);
}


.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

button.MuiButton-root {
  font-family: var(--body-fontFamily);
  font-size: 14px;
}

/* font size classes */
div .font-10 {
  font-size: 10px;
}

div .font-12 {
  font-size: 12px;
}

div .font-13 {
  font-size: 13px;
}

div .font-14 {
  font-size: 14px;
}

div .font-16 {
  font-size: 16px;
}

div .font-18 {
  font-size: 18px;
}

div .font-22 {
  font-size: 22px;
}

div .font-24 {
  font-size: 24px;
}

div .font-28 {
  font-size: 28px;
}

div .font-32 {
  font-size: 32px;
}

div .font-36 {
  font-size: 36px;
}

div .font-45 {
  font-size: 45px;
}

div .font-56 {
  font-size: 56px;
}

div .font-64 {
  font-size: 64px;
}

div .fw-500 {
  font-weight: 500;
}

/* Text Color Classes */
.text-white {
  color: var(--white-color);
}

.text-black {
  color: var(--black-color);
}

.text-red {
  color: var(--red-600);
}

.text-blue-900 {
  color: var(--blue-900);
}

.text-blue-600 {
  color: var(--blue-600);
}

.text-blue-300 {
  color: var(--blue-300);
}

.text-blueGray-600 {
  color: var(--blueGray-600);
}

.text-blueGray-500 {
  color: var(--blueGray-500);
}

.text-blueGray-400 {
  color: var(--blueGray-400);
}

.text-blueGray-300 {
  color: var(--blueGray-300);
}

.text-yellow-700 {
  color: var(--yellow-700);
}

.text-yellow-100 {
  color: var(--yellow-100);
}

.text-yellow-50 {
  color: var(--yellow-50);
}

.text-golden {
  background: var(--golden-gradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

/* Background Color Classes */

div .bg-golden {
  background: var(--golden-gradient);
}

div .bg-blue-900 {
  background-color: var(--blue-900);
}

.bg-blue-800 {
  background-color: var(--blue-800);
}

.bg-blue-700 {
  background-color: var(--blue-700);
}

.bg-blue-600 {
  background-color: var(--blue-600);
}

.bg-blue-500 {
  background-color: var(--blue-500);
}

.bg-blue-400 {
  background-color: var(--blue-400);
}

.bg-blueGray-100 {
  background-color: var(--blueGray-100);
}

.bg-blueGray-400 {
  background-color: var(--blueGray-400);
}

.bg-blueGray-900 {
  background-color: var(--blueGray-900);
}

.bg-yellow-50 {
  background-color: var(--yellow-50);
}

.bg-yellow-500 {
  background-color: var(--yellow-500);
}

.bg-text-yellow-700 {
  background-color: var(--yellow-700);
}

.bg-red-500 {
  background-color: var(--red-500);
}

.bg-green-500 {
  background-color: var(--green-500);
}

.w-100 {
  width: 100%;
}

/* Layout css  */

.main-page .sidebar-left {
  width: 100%;
  max-width: 250px;
  position: fixed;
  top: 64px;
  left: 0;
  background-color: var(--blue-900) !important;
  border-radius: 0px 16px 16px 0px;
}

.main-layout .main-page .main-contant {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  padding: 20px;
  padding: 80px 24px 48px;
}

.main-layout .main-page .mydocument {
  padding-top: 0px;
}

.main-layout .main-page .main-contant.chat-section {
  padding: 80px 0px 48px;
}

.main-layout .main-page .main-contant.chats-active {
  padding: 80px 0px 48px;
}

.main-layout .main-page.chat-wrapper-dic .main-contant {
  padding: 80px 0 64px 0px;
}

/* .portfolio-slider-wrap .slick-slider {
   margin: 0 -12px;
}
.portfolio-slider-wrap .slick-slide {
   margin-right: 12px;
   margin-left: 12px;
} */
/*================================= 
Investor Layout
=================================*/

.investor-layout .main-page {
  margin-top: 64px;
  overflow-x: clip;
}

.investor-layout .investor-main-page {
  background-color: var(--blue-900);
}

/*================================= 
Button Design
=================================*/
.btn-rounded,
button.btn-rounded {
  font-size: var(--font-base);
  color: var(--black-color);
  background: var(--blue-50);
  border-radius: 40px;
  height: 40px;
  line-height: 10px;
  padding: 6px 24px;
  font-weight: 500;
  text-transform: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  box-shadow: none;
  border: none;
}

a.btn-rounded {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-large,
button.btn-large {
  border-radius: 16px;
  height: 52px;
  letter-spacing: 0.1px;
}

.btn-rounded:hover,
button.btn-rounded:hover {
  color: var(--black-color);
  background: var(--blue-50);
}

.btn-roundeds,
button.btn-rounded {
  font-size: var(--font-base);
  color: var(--black-color);
  background: var(--blue-50);
  border-radius: 40px;
  height: 40px;
  line-height: 10px;
  padding: 6px 24px;
  font-weight: 500;
  text-transform: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  white-space: nowrap;
  box-shadow: none;
  border: none;
}

a.btn-roundeds {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-large,
button.btn-large {
  border-radius: 16px;
  height: 52px;
  letter-spacing: 0.1px;
}

.btn-roundeds:hover,
button.btn-roundeds:hover {
  color: var(--black-color);
  background: var(--blue-50);
}

.btn-rounded.btn-text-white,
button.btn-text-white .btn-rounded.btn-text-white:hover,
button.btn-text-white:hover {
  color: var(--white-color);
}

.btn-rounded.btn-text-yellow-900,
button.btn-text-yellow-900 .btn-rounded.btn-text-yellow-900:hover,
button.btn-text-yellow-900:hover {
  color: var(--yellow-900);
}

.MuiButtonBase-root.Mui-disabled.btn-rounded.btn-text-white {
  color: rgba(255, 255, 255, 0.5);
}

/** Button green-400 */
.btn-rounded.btn-green-400,
button.btn-green-400 {
  background-color: var(--green-400);
}

.btn-rounded.btn-green-400:hover,
button.btn-green-400:hover {
  background-color: var(--green-400);
}

/** Button teal-100 */
.btn-rounded.btn-teal-100,
button.btn-teal-100 {
  background-color: var(--Palette-Teal-100);
}

.btn-rounded.btn-teal-100:hover,
button.btn-teal-100:hover {
  background-color: var(--blueGray-300);
}

/* Button blue-100 */
.btn-rounded.btn-blue-100,
button.btn-blue-100 {
  background-color: var(--blue-100);
}

.btn-rounded.btn-blue-100:hover,
button.btn-blue-100:hover {
  background-color: var(--blue-100);
}

/* Button yellow-50 */
.btn-rounded.btn-yellow-50,
button.btn-yellow-50 {
  background-color: var(--yellow-50);
}

button.btn-white-50 {
  background-color: var(--white-color);
}

.btn-rounded.btn-yellow-50:hover,
button.btn-yellow-50:hover {
  background-color: var(--yellow-50);
}

/* Button Blue Gray 200 */
.btn-rounded.btn-blueGray-200,
button.btn-blueGray-200 {
  background-color: var(--blueGray-200);
}

.btn-rounded.btn-blueGray-200:hover,
button.btn-blueGray-200:hover {
  background-color: var(--blueGray-200);
}

/* Button Blue Gray 400 */
.btn-rounded.btn-blueGray-400,
button.btn-blueGray-400 {
  background-color: var(--blueGray-400);
}

.btn-rounded.btn-blueGray-400:hover,
button.btn-blueGray-400:hover {
  background-color: var(--blueGray-300);
}

/* Button Blue 300 */
.btn-rounded.btn-blue-300,
button.btn-blue-300 {
  background-color: var(--blue-300);
}

.btn-rounded.btn-blue-300:hover,
button.btn-blue-300:hover {
  background-color: var(--blue-300);
}

.btn-rounded.btn-blue-600,
button.btn-blue-600 {
  background-color: var(--blue-600);
}

.btn-rounded.btn-blue-600:hover,
button.btn-blue-600:hover {
  background-color: var(--blue-600);
}

.btn-rounded.btn-blue-800,
button.btn-blue-800 {
  background-color: var(--blue-800);
}

.btn-rounded.btn-blue-800:hover,
button.btn-blue-800:hover {
  background-color: var(--blue-800);
}

.btn-rounded.btn-blue-500,
button.btn-blue-500 {
  background-color: var(--blue-500);
}

.btn-rounded.btn-blue-500:hover,
button.btn-blue-500:hover {
  background-color: var(--blue-500);
}

.btn-rounded.btn-yellow-100,
button.btn-yellow-100 {
  background-color: var(--yellow-100);
}

.btn-rounded.btn-yellow-100:hover,
button.btn-yellow-100:hover {
  background-color: var(--yellow-100);
}

.btn-rounded.btn-yellow-60,
button.btn-yellow-60 {
  background-color: var(--yellow-60);
  border-radius: 12px;
}

.btn-rounded.btn-yellow-60:hover,
button.btn-yellow-60:hover {
  background-color: var(--yellow-60);
}

/* Golden button */
.btn-rounded.btn-golden,
button.btn-golden {
  background: var(--golden-gradient);
}

.btn-rounded.btn-golden:hover,
button.btn-golden:hover {
  background: var(--golden-gradient);
}

/* Button outline */
.btn-rounded.btn-outline,
button.btn-outline {
  background-color: transparent;
  color: var(--white-color);
  border: solid 2px var(--blue-200);
  padding-left: 18px;
  padding-right: 18px;
}

.btn-outline.btn-yellow-100,
button.btn-outline.btn-yellow-100,
button.btn-outline.btn-yellow-100:hover {
  border-color: var(--yellow-100);
}

.btn-rounded.btn-outline:hover,
button.btn-outline:hover {
  background-color: transparent;
  color: var(--white-color);
  border: solid 2px #945b3b;
}

.edit-grey-icon button.btn-golden-outline,
button.btn-golden-outline {
  padding: 0px;
  border: 1px solid transparent;
  position: relative;
  color: #945B3B;
  background: var(--golden-gradient);
  outline: none;
  box-shadow: none;

}

button.btn-golden-outline .btn-inner {
  background-color: var(--blue-900);
  width: 100%;
  height: 100%;
  padding: 15px 15px;
  border-radius: 16px;
}

/* button.btn-golden-outline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--golden-gradient);
  z-index: -1;
} */
.btn-wrap.multi-btns {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.btn-wrap.multi-btns .btn-rounded {
  margin-top: 8px;
}

.btn-wrap.multi-btns .btn-rounded+.btn-rounded {
  margin-left: 12px;
}

.home-exploreBanner-wrap .btn-wrap.multi-btns {
  gap: 15px;
}

.home-exploreBanner-wrap .btn-wrap.multi-btns .btn-rounded+.btn-rounded {
  margin-left: 0;
}

/* .squareHelp-btn.btn-rounded {
  width: 84px;
  height: 84px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  border-radius: 28px;
  background: #FFF8F3;
} */

.squareHelp-btn.btn-rounded {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 24px;
  right: 25px;
  border-radius: 15px;
  background: #fff8f3;
}

button.square-icon-btn {
  border-radius: 12px;
  background-color: var(--blue-500);
}

button.square-icon-btn:hover {
  background-color: var(--blue-500);
}

button.square-icon-btn svg {
  color: var(--white-color);
}

.square-chip.MuiChip-filled {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  border-radius: 8px;
  background-color: var(--blueGray-900);
}

/*================================= 
Dropdown Menu
=================================*/
.MuiPaper-root.MuiMenu-paper {
  background-color: transparent;
  color: var(--white-color);
}

.dropdown-menu .MuiMenu-paper.MuiPopover-paper,
.MuiPaper-root>.MuiList-root.MuiMenu-list {
  background-color: var(--blue-900);
}

.dropdown-menu ul li,
.MuiPaper-root>.MuiList-root li.MuiMenuItem-root {
  font-size: var(--font-base);
  color: var(--white-color);
  font-weight: 400;
  min-height: 48px;
}

.dropdown-menu ul li .MuiListItemIcon-root,
.MuiPaper-root>.MuiList-root li.MuiMenuItem-root .MuiListItemIcon-root {
  color: var(--white-color);
}

.dropdown-menu ul li.Mui-selected,
.dropdown-menu ul li.Mui-selected:hover,
.dropdown-menu ul li.active,
.dropdown-menu ul li:hover,
.MuiPaper-root>.MuiList-root li.MuiMenuItem-root.Mui-selected,
.MuiPaper-root>.MuiList-root li.MuiMenuItem-root.Mui-selected:hover,
.MuiPaper-root>.MuiList-root li.MuiMenuItem-root.active,
.MuiPaper-root>.MuiList-root li.MuiMenuItem-root:hover {
  color: var(--white-color);
  background-color: var(--blue-600);
}

/*================================= 
Section heading
=================================*/
.section-heading h3.title {
  font-size: 45px;
  font-weight: 400;
}

.section-heading .subtitle {
  font-size: 18px;
  margin-top: 24px;
}

.small-heading {
  margin-bottom: 26px;
}

.small-heading .title {
  color: var(--white-color);
  font-size: 22px;
  font-weight: 700;
}

.small-heading .icon .MuiAvatar-root {
  width: 28px;
  height: 28px;
  margin-left: 10px;
}

/*================================= 
Form default design
=================================*/
.form-group {
  margin-bottom: 30px;
  position: relative;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--blueGray-300);
  border-radius: 8px;
}

.MuiFormControl-root .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--blue-600);
}

.MuiFormControl-root .Mui-readOnly fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--blueGray-500);
}

.MuiFormControl-root .MuiButtonBase-root.Mui-disabled {
  opacity: 0.7;
}

.dark-form .MuiFormControl-root .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--green-500);
}

.MuiFormControl-root label.MuiFormLabel-root {
  font-family: var(--body-fontFamily);
  color: var(--blueGray-400);
  font-size: var(--font-base);
  font-weight: 400;
  /* left: 3px; */
}

.MuiFormControl-root input.MuiInputBase-input,
input.form-control {
  font-family: var(--body-fontFamily);
  font-size: 16px;
  color: var(--blueGray-900);
  font-weight: 400;
}

.MuiFormControl-root .MuiInputBase-root.Mui-readOnly input.Mui-readOnly,
.MuiFormControl-root textarea.MuiInputBase-input.MuiInputBase-readOnly {
  color: var(--blueGray-400);
}

.MuiInputBase-root .MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: var(--blueGray-400);
}

.MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-positionEnd {
  margin-right: -8px;
}

/* Normal Input design */
input.form-control {
  padding: 16.5px 14px;
  height: 54px;
  border: solid 1px var(--blueGray-300);
  border-radius: 8px;
}

input.form-control:hover {
  border-color: var(--blue-400);
}

input.form-control:focus-visible {
  outline-color: var(--blue-400);
}

/*  Start Form Select  design */
.select-rounded.MuiFormControl-root .MuiInputBase-root,
.input-rounded.MuiFormControl-root .MuiInputBase-root {
  border-radius: 30px;
  height: 40px;
}

/* .MuiFormControl-root.select-rounded .MuiFormLabel-root{
   transform: translate(16px, 0) scale(1);
 } */
.select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSvgIcon-root {
  color: var(--white-color);
}

.select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined,
.input-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined,
.MuiFormControl-root.input-rounded input.MuiInputBase-input {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  padding: 8.5px 16px;
  padding-right: 32px;
}

.select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined,
.input-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined {
  display: flex;
  align-items: center;
}

.select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined .MuiListItemText-root,
.input-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined .MuiListItemText-root {
  flex: 0 0 auto;
  margin: 0;
}

.select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined .menu-icon,
.input-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined .menu-icon {
  margin-left: 10px;
}

.MuiFormControl-root.input-rounded input.MuiInputBase-input {
  padding-right: 16px;
}

.select-rounded.MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline,
.input-rounded.MuiFormControl-root fieldset.MuiOutlinedInput-notchedOutline {
  border-width: 2px;
  border-color: var(--blue-200);
  border-radius: 30px;
}

.MuiFormControl-root.select-rounded .MuiInputLabel-root,
.MuiFormControl-root.input-rounded label.MuiFormLabel-root,
.select-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined .MuiListItemText-root .MuiTypography-root,
.input-rounded.MuiFormControl-root .MuiInputBase-root .MuiSelect-select.MuiSelect-outlined .MuiListItemText-root .MuiTypography-root {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
}

.MuiFormControl-root.select-rounded .MuiInputLabel-root svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  margin-left: 6px;
}

.MuiFormControl-root.select-rounded label.MuiFormLabel-root.MuiFormLabel-filled,
.MuiFormControl-root.input-rounded label.MuiFormLabel-root.MuiFormLabel-filled {
  color: var(--white-color);
  font-weight: 400;
}

.MuiFormControl-root.select-rounded label.MuiFormLabel-root.MuiFormLabel-filled+.MuiInputBase-root .MuiOutlinedInput-notchedOutline legend {
  padding-right: 9px;
}

.MuiFormControl-root.select-rounded .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline,
.MuiFormControl-root.select-rounded .MuiSvgIcon-root.MuiSelect-iconOpen+fieldset.MuiOutlinedInput-notchedOutline,
.MuiFormControl-root.input-rounded .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline,
.MuiFormControl-root.input-rounded .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  /* border-color: var(--yellow-100); */
  border-color: #945b3b;
}

.input-rounded .MuiInputAdornment-root .MuiButtonBase-root svg {
  color: #fff;
  width: 18px;
  height: 18px;
}

.input-rounded .MuiInputAdornment-root .MuiButtonBase-root {
  margin-right: -3px;
}

/* .MuiFormControl-root.select-rounded label.MuiFormLabel-root.MuiFormLabel-filled svg {
   display: none;
} */

/* input[type=number].input-rounded::-webkit-outer-spin-button,
input[type=number].input-rounded::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number].input-rounded {
  -moz-appearance: textfield;
  appearance: textfield;
} */

/*  End Form Select  design */

.dark-form .MuiFormControl-root input.MuiInputBase-input {
  color: var(--white-color);
}

.MuiFormControl-root label.Mui-focused {
  /* color: var(--blueGray-900); */
  /* change 22 08 2023 */
  color: var(--blue-600);
}

.MuiFormControl-root label.MuiFormLabel-filled {
  color: var(--blueGray-900);
}

.dark-form .MuiFormControl-root label.Mui-focused {
  color: var(--green-500);
}

.MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--blue-600);
}

.dark-form .MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.dark-form .MuiFormControl-root .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--green-500);
}

.checkbox-dark .MuiButtonBase-root.MuiCheckbox-colorPrimary {
  color: var(--blue-800);
}

.checkbox-dark .MuiButtonBase-root.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--blue-800);
}

.checkbox-dark .MuiFormControlLabel-label {
  font-family: var(--body-fontFamily);
  color: var(--blue-800);
  font-size: 14px;
  font-weight: 500;
}

.or-divider {
  font-size: 12px;
  color: var(--blueGray-300);
  margin: 20px 0;
  position: relative;
  letter-spacing: 0.4px;
  text-align: center;
}

.or-divider:after,
.or-divider:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  right: auto;
  width: calc(50% - 24px);
  height: 1px;
  background-color: var(--blueGray-300);
}

.or-divider:after {
  right: 0;
  left: auto;
  width: calc(50% - 24px);
}

/* Large primary swich */

.largeDark-swich {
  display: flex;
  align-items: center;
  height: 32px;
}

.largeDark-swich label.MuiFormControlLabel-root {
  margin: 0px 10px;
}

.largeDark-swich label .MuiSwitch-root {
  width: 52px;
  height: 32px;
}

.largeDark-swich label .MuiSwitch-switchBase+.MuiSwitch-track,
.largeDark-swich label .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  /* background-color: var(--blue-400); */
  border-radius: 34px;
}

.largeDark-swich label .MuiSwitch-thumb {
  width: 28px;
  height: 28px;
  position: relative;
}

.largeDark-swich label .MuiSwitch-thumb:after {
  content: "";
  position: absolute;
  width: 28px;
  height: 28px;
  background-image: url("./../Images/blue-dark-user.png");
  background-size: 12px auto;
  background-position: center;
  background-repeat: no-repeat;
}

.largeDark-swich label .MuiSwitch-switchBase,
.largeDark-swich label .MuiSwitch-switchBase.Mui-checked {
  color: var(--blueGray-400);
}

.largeDark-swich label .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb:after {
  background-image: url("./../Images/blue-dark-bag.png");
  background-size: 14px auto;
}

.largeDark-swich label .MuiSwitch-switchBase.Mui-checked {
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.largeDark-swich .MuiTypography-root {
  /* color: var(--blue-900); */
  font-size: 14px;
  font-weight: 500;
}

/* Dark Checkbox */
.darkCheckbox .MuiRadio-root {
  color: var(--blueGray-900);
}

.darkCheckbox .MuiRadio-root.Mui-checked {
  color: var(--blue-800);
}

/*================================= 
Buttons Design
=================================*/

.MuiButtonBase-root.blueLite-btn {
  font-family: var(--body-fontFamily);
  color: var(--blue-800);
  font-weight: 500;
  font-size: 14px;
  background: var(--blueGray-200);
  text-transform: capitalize;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  height: 52px;
}

.MuiButtonBase-root.blueLite-btn:hover {
  background: var(--blue-900);
  color: var(--white-color);
}

/*================================= 
Drag & Drop
=================================*/
.ctm-dragDrop {
  color: var(--blue-900);
  cursor: pointer;
  border-radius: 12px 12px 0px 0px;
  border: 2px dashed var(--blueGray-400);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: var(--blueGray-100);
}

.ctm-dragDrop:hover {
  border-color: var(--blue-600);
}

/*================================= 
Large icon Thumbnail
=================================*/
.lg-icon-thumbnail {
  border-radius: 24px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  /* max-width: 445px;
  margin-left: auto;
  margin-right: auto; */
}

.lg-icon-wrap .MuiAvatar-root {
  width: 80px;
  height: 80px;
  padding: 17px 15px;
  border-radius: 24px;
}

.lg-icon-wrap .MuiAvatar-root svg {
  width: 40px;
  height: 40px;
}

.lg-icon-wrap .MuiAvatar-root img {
  width: auto;
  max-width: 48px;
  height: auto;
}

/*================================= 
React multi carousel Gutter
=================================*/
.space-24.react-multi-carousel-list {
  margin-left: -12px;
  margin-right: -12px;
}

.space-24.react-multi-carousel-list li .slide-item {
  padding: 0 12px;
}

.react-multi-carousel-list .react-multiple-carousel__arrow {
  top: 2px;
  right: 0;
  left: auto;
}

/* Top Navigation Desing */
.productThumb-slider.react-multi-carousel-list {
  padding-top: 50px;
  margin-top: -55px;
}

.productThumb-slider.react-multi-carousel-list li .slide-item {
  height: 100%;
  /* max-width: 350px; */
}

.productThumb-slider.react-multi-carousel-list .react-multiple-carousel__arrow {
  top: 0;
  right: 12px;
  left: auto;
  width: 30px;
  height: 28px;
  min-width: 30px;
  min-height: 28px;
  background: var(--blue-600);
  z-index: 9;
  border-radius: 8px;
}

.productThumb-slider.react-multi-carousel-list .react-multiple-carousel__arrow::before {
  font-size: 15px;
}

.productThumb-slider.react-multi-carousel-list .react-multiple-carousel__arrow:hover {
  background-color: var(--yellow-50);
}

.productThumb-slider.react-multi-carousel-list .react-multiple-carousel__arrow:hover::before {
  color: var(--black-color);
}

.productThumb-slider.react-multi-carousel-list .react-multiple-carousel__arrow--left {
  right: 42px;
  border-radius: 8px 0 0 8px;
}

.productThumb-slider.react-multi-carousel-list .react-multiple-carousel__arrow--left+.react-multiple-carousel__arrow--right {
  border-radius: 0 8px 8px 0;
}

/*================================= 
Vertical Tab Content
=================================*/
.verticalTab-wrap .MuiTabs-root {
  overflow: visible;
}

.verticalTab-wrap .MuiTabs-root .MuiTabs-indicator {
  display: none;
}

.verticalTab-wrap .verticalTab-content {
  padding-left: 16px;
  width: 100%;
}

.verticalTab-wrap .MuiTabs-root button.MuiTab-root {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  height: 56px;
  padding: 16px;
  min-height: 56px;
  border-radius: 100px;
  background: transparent;
  justify-content: space-between;
  text-transform: capitalize;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.verticalTab-wrap .MuiTabs-root button.MuiTab-root .MuiSvgIcon-root {
  opacity: 0;
  visibility: hidden;
}

.verticalTab-wrap .MuiTabs-root button.MuiTab-root.Mui-selected .MuiSvgIcon-root {
  opacity: 1;
  visibility: visible;
}

.verticalTab-wrap .MuiTabs-root button.MuiTab-root.Mui-selected {
  background: var(--blue-600);
}

/* css to remove spinner from input type number */
.no-spinners input::-webkit-inner-spin-button,
.no-spinners input::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

/*================================= 
overlayscrollbars-react scrollbar Design
=================================*/
.os-scrollbar {
  padding-right: 0;
  padding-left: 0;
  width: 4px;
}

.os-scrollbar .os-scrollbar-track {
  background: var(--blueGray-200);
  border-radius: 0;
  border: none;
  width: 4px;
}

.os-scrollbar .os-scrollbar-handle {
  background: var(--blue-600);
  border-radius: 0;
  border: none;
}

/* Project Status live/Rejected */

.MuiChip-root.pc-chip-status {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 8px;
  background: var(--green-500);
  text-transform: capitalize;
  height: 28px;
  line-height: 28px;
  position: absolute;
  top: -14px;
  left: 0;
}

.MuiChip-root.pc-chip-capitalinfo {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 8px;
  background: var(--green-500);
  text-transform: capitalize;
  height: 28px;
  margin-top: 3px;
  /* line-height: 28px;
  position: absolute;
  top: -14px;
  left: 0; */
}

.MuiChip-root.pc-chip-status.status-rejected {
  background: var(--red-500);
}

.MuiChip-root.pc-chip-status.status-inprogress {
  background: var(--yellow-500);
}

.MuiChip-root.pc-chip-status.status-completed {
  background: var(--yellow-500);
}

/*================================= 
Connect Wallet Popup
=================================*/
.connectWallet-popup .MuiDialog-container .MuiPaper-root {
  background: var(--blue-800);
  width: 100%;
  max-width: 470px;
  border-radius: 24px;
}

.connectWallet-popup .cwp-header h4 {
  font-size: 24px;
  text-align: center;
  color: #fff;
  font-weight: 500;
}

.connectWallet-popup .cwp-header p {
  font-size: 14px;
  text-align: center;
  color: #fff;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 10px;
}

.connectWallet-popup .cwp-content {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}

.connectWallet-list li.MuiListItem-root {
  padding: 0;
}

.connectWallet-list .MuiListItemAvatar-root {
  min-width: 50px;
}

.connectWallet-list .MuiAvatar-root {
  width: 34px;
  height: 34px;
}

.connectWallet-list .MuiListItemText-root .MuiTypography-root {
  font-size: 16px;
  color: var(--white-color);
}

.connectWallet-list li.MuiListItem-root .MuiButtonBase-root {
  padding-left: 24px;
}

.connectWallet-list li.MuiListItem-root .MuiListItemSecondaryAction-root {
  right: 15px;
}

.connectWallet-list li.MuiListItem-root .MuiListItemSecondaryAction-root .MuiCircularProgress-root {
  width: 24px !important;
  height: 24px !important;
}

#wcm-modal.wcm-overlay {
  z-index: 1301;
}

.investor-main-page {
  background-color: #010509;
  height: 100vh;
}

.investor-only-main-page {
  background-color: #010509;
  height: 100%;
  /* padding-bottom: 40px; */
  /* padding-bottom: 142px; */
}

.autofill-text-white input:-internal-autofill-selected {
  background-color: transparent !important;
  color: #ffffff !important;
  background-image: none !important;
  -webkit-text-fill-color: #ffffff;
}

.payment-enter-investor input:-webkit-autofill,
.autofill-text-white input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff;
}

.payment-enter-investor input:-webkit-autofill:focus,
.autofill-text-white input:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
}

.filter-btn fieldset.MuiOutlinedInput-notchedOutline {
  border: 2px solid red;
}

.pagination-container {
  width: 100%;
}

.cross-icon-modal {
  color: #fff !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .pagination-container {
    margin-left: 0px !important;
  }
}

.red-asterisk::after {
  content: "*";
  color: red;
  margin-left: 0.2em;
}

.label-field .MuiFormLabel-root {
  display: flex;
  align-items: center;
}



/*================================= 
End
=================================*/