.onboarding-profile-main .onboarding-profile-container {
  padding: 48px;
}

.onboarding-profile-main
  .onboarding-profile-containersvg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  background: red;
}

.logo-onboarding-one {
  height: 48px;
  width: 48px;
  padding-left: 5px;
  padding-top: 5px;
  margin: 12px 20px;
}

/* professional info css */
.onboarding-professional-info-container .onboarding-professional-main {
  padding: 48px;
}

.professional-info-left-container .heading {
  font-weight: 400;
  color: var(--white-color);
}

.professional-info-left-container .sub-heading {
  margin-top: 24px;
  font-weight: 400;
  color: var(--white-color);
}

.professional-info-left-container .btn-linkedin {
  background-color: var(--blue-600);
  color: var(--white-color);
  padding: 0px 43px;
}

.professional-info-left-container .btn-linkedin:hover {
  background-color: var(--blue-600);
  color: var(--white-color);
}

.professional-info-left-container .proff-info-box {
  background-color: var(--blue-900);
  padding: 16px;
  border-radius: 12px;
}

.professional-info-left-container .proff-info-box .title {
  color: var(--white-color);
  font-weight: 400;
}

.professional-info-left-container .proff-info-box .sub-title {
  color: var(--blueGray-300);
  font-weight: 400;
}

.professional-info-left-container .proff-info-box input {
  color: #fff;
}

.professional-info-left-container .proff-info-box .work-exper-box {
  background-color: var(--blue-700);
  padding: 16px;
  width: 13rem;
  border-radius: 12px;
  margin-bottom: 5px;
  
}
.work-ex-box{
  margin-right: 10px;
}
.onboarding-service-left-container .proff-info-box .work-exper-box {
  background-color: var(--blue-700);
  padding: 16px;
  width: 13rem;
  border-radius: 12px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: 15px;
}

.proff-info-box .work-upload-doc {
  width: 15rem;
}

.highlight {
  border: 1px solid white;
}
.onboarding-service-left-container
  .MuiFormControl-root
  label.MuiFormLabel-filled {
  color: var(--white-color);
}
.add-icon-box {
  background: var(--white-color);
  width: 64px;
  height: 64px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-icon-box .icon {
  color: var(--blue-700);
}

.work-exper-box .title {
  margin-top: 96px;
  font-weight: 500;
  color: var(--white-color);
}

.proff-info-box .header {
  background-color: var(--blueGray-900);
  border-radius: 12px;
  display: flex;
  align-items: center;
}

.proff-info-box .header .header-heading {
  width: 33.33%;
  text-align: left;
  color: var(--blueGray-400);
  font-weight: 500;
}

.proff-info-box .header .action {
  text-align: center;
}

.proff-info-box .list-data {
  display: flex;
  align-items: center;
}

.proff-info-box .list-data .data {
  display: flex;
  width: 100%;
  margin-top: 28px;
  align-items: center;
}

.proff-info-box .list-data .data .lang-name {
  width: 33.33%;
  padding-left: 20px;
}

.proff-info-box .list-data .data .select-lang {
  width: 33.33%;
}

.list-data .edit-lang {
  color: var(--white-color);
  background: var(--blueGray-900);
  border-radius: 8px;
  height: 38px;
  width: fit-content;
  padding: 10px;
  font-size: 16px;
}

.proff-info-box .list-data .data .data-icon {
  margin-left: 30px;
  margin-right: 40px;
}

.proff-info-box .list-data .data .icons {
  width: 33.33%;
  display: flex;
  justify-content: end;
}

.list-data
  .select-lang
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.proficiency-select {
  color: var(--white-color);
  background: var(--blueGray-900);
  border-radius: 8px;
  height: 38px;
}

.proff-info-box .list-data .data button {
  background-color: var(--blueGray-900);
  color: var(--white-color);
  text-transform: capitalize;
  border-radius: 12px;
}

.onboarding-profile-main
  .onboarding-profile-containersvg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  background: red;
}

.sp-box-main {
  padding-left: 40px;
}

.sp-stepper-onboarding.MuiChip-root,
.sp-stepper-onboarding-nc.MuiChip-root,
.sp-stepper-onboarding-cm.MuiChip-root {
  background: var(--golden-gradient);
  color: var(--golden-gradient);
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.sp-stepper-onboarding-nc.MuiChip-root {
  background: var(--blueGray-400);
}

.sp-stepper-onboarding-cm.MuiChip-root {
  background: green;
}

.sp-stepper-main .MuiStepConnector-root {
  display: none;
}

.sp-stepper-main .MuiStep-root .MuiStepLabel-label {
  color: var(--golden-gradient);
}

.sp-stepper-main {
  padding-top: 50px;
  width: 100%;
  justify-content: space-between;
}

.button-div button {
  margin-right: 10px;
  margin-bottom: 10px;
  color: var(--white-color);
  background-color: var(--blue-700);
  border-radius: 8px;
}


.button-div-bg-none button {
  margin-right: 10px;
  margin-bottom: 10px;
  color: var(--white-color);
  border-radius: 8px;
}

.button-div-bg-none .skill-button {
  background-color: var(--blue-700);
}

.red {
  background-color: var(--blueGray-400);
}

.blue {
  background-color: blue;
}

.green {
  background-color: green;
}

.active-step {
  background-color: var(--golden-gradient);
  color: var(--white-color);
}

.balck {
  background-color: black;
}

.onboarding-profile-left-container input:-webkit-autofill,
.onboarding-profile-left-container input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

/* .onboarding-service-left-container {
    padding: 48px;
} */

.onboarding-service-left-container .heading {
  font-weight: 400;
  color: var(--white-color);
}

.onboarding-service-left-container .sub-heading {
  margin-top: 24px;
  font-weight: 400;
  color: var(--white-color);
}

.onboarding-service-left-container .proff-info-box {
  background-color: var(--blue-900);
  padding: 16px;
  border-radius: 12px;
}

.onboarding-service-left-container input:-webkit-autofill,
.onboarding-service-left-container input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.onboarding-service-left-container .proff-info-box input {
  color: #fff;
}

.hour-rate-input label {
  background-color: var(--blueGray-900);
  padding: 0px 5px;
}
#youWillGet {
  background-color: var(--blue-500);
  border-radius: 10px;
}
#youWillGet-label {
  background-color: black;
  color: white;
  padding: 0px 5px;
}
#serviceFee {
  background-color: var(--blue-500);
  border-radius: 10px;
}
#serviceFee-label {
  background-color: black;
  color: white;
  padding: 0px 5px;
}
#hourlyRate-label {
  background-color: black;
  color: white;
  padding: 0px 5px;
}

.assoc-data {
  background-color: var(--blueGray-900);
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.list-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onboarding-review-page-container {
  padding: 0px 37px 48px;
}
.onboarding-review-page-container .my-profile-box {
  background-color: var(--blue-900);
  border-radius: 24px;
  /* margin-top: 24px; */
}
.onboarding-review-page-container .right-section {
  margin-top: 46px;
}
.onboarding-review-page-container .review-profile-top {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.associ-box {
  background-color: var(--blue-700);
  border-radius: 12px;
  padding: 8px 8px 3px;
  display: flex;
  align-items: center;
  margin-top: 6px;
}
.associ-box .title {
  color: #aedaff;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  color: var(--white-color);
}
.search-lang .MuiFormControl-root label.MuiFormLabel-root {
  background-color: var(--blueGray-900);
  color: var(--white-color);
  padding: 2px 10px;
}

.skill-btn-badge button{
  margin-right: 0;
}
.skill-btn-badge .MuiBadge-root{
  margin-right: 15px;
}
.skill-btn-badge .MuiBadge-root .MuiBadge-badge{
  padding: 0px 4px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .sp-box-main span .MuiStepLabel-iconContainer {
    width: max-content;
  }
  .sp-box-main .MuiStepper-root.MuiStepper-horizontal.sp-stepper-main {
    overflow: auto;
  }
  .onboarding-professional-info-container .onboarding-professional-main{
    padding: 20px;
  }
  .proff-info-box .list-data .data .data-icon {
    margin-right: 25px;
  }
  .onboarding-profile-main .onboarding-profile-container{
    padding: 20px;
  }
}
