.rc-basic-container.MuiPaper-root {
  color: var(--white-color);
  background-color: var(--blue-900);
  border-radius: 12px;
  padding: 12px 24px;
  box-sizing: border-box;
  margin-bottom: 12px;
}

.rc-hr.MuiBox-root {
  margin-top: 16px;
  border-top: 0.5 solid var(--blueGray-700);
  border-color: var(--blueGray-700);
  width: 100%;
  height: 0.5px;
}

.rc-tertiary-btn.MuiButton-root {
  color: #0f172a;
  padding: 2px 6px;
  border-radius: 24px;
  text-transform: none;
  /* background-color: var(--golden-gradient); */
  transition: all 0.2s ease-in-out;
}

.rc-tertiary-btn.MuiButton-root:hover {
  /* background-color: var(--blue-50); */
  /* color: var(--white-color); */
}

.rc-tertiary-btn-blue.MuiButton-root {
  color: var(--white-color);
  padding: 12px 20px;
  border-radius: 12px;
  text-transform: none;
  background-color: var(--blue-800);
  transition: all 0.2s ease-in-out;
}

.rc-tertiary-btn-blue.MuiButton-root:hover {
  background-color: var(--blue-500);
  color: var(--white-color);
}

.rc-primary-btn-rounded.MuiButton-root {
  padding: 8px 24px;
  /* color: var(--blueGray-900); */
  border-radius: 40px;
  text-transform: none;
  background-color: var(--blue-500);
}

.rc-primary-btn-outline-rounded.MuiButton-root {
  box-sizing: border-box;
  padding: 8px 24px;
  border-radius: 40px;
  text-transform: none;
  border: 1px solid var(--blue-500);
  color: var(--blue-500);
  font-weight: 500;
}

.payment-sale-investor .stPi-card {
  background-color: var(--blue-800);
  border-radius: 15px;
  color: #f1f5f9;
  margin-top: 8px;
}

.payment-sale-investor .stPi-save-btn {
  flex-direction: row;
  width: 146px;
  height: 40px;
  text-transform: none;
  background: var(--blue-500);
  border-radius: 40px;
  color: #000000;
  margin: 20px;
}

.rc-small-secondary-text.MuiTypography-root {
  color: var(--blueGray-300);
  letter-spacing: 0.5px;
}

.rc-terms-checkbox.MuiFormControlLabel-root {
  box-sizing: border-box;
  border-radius: 12px;
  margin-left: 0px;
  background-color: var(--yellow-50);
  color: #0f172a;
  width: 100%;
}

.rc-detail-subtitle.MuiTypography-root {
  margin-top: 10px;
}

.rc-document-box.MuiBox-root {
  box-sizing: border-box;
  background-color: var(--blue-600);
  border-radius: 6px;
  padding: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rc-document-icon.MuiBox-root {
  display: flex;
  padding: 8px;
  align-items: center;
  background-color: var(--blue-800);
  color: var(--blue-200);
  border-radius: 6px;
}

.rc-xsmall-secondary-text.MuiTypography-root {
  color: var(--blueGray-400);
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

/* accordion right side */
.rc-detail-subtitle.MuiTypography-root {
  margin-top: 10px;
}

.rc-faq-accordion.MuiAccordion-root {
  background-color: transparent;
  border: 0px;
}

.rc-faq-accordion.MuiAccordion-root svg {
  color: var(--blueGray-400);
}

.rc-faq-summary.MuiAccordionSummary-root {
  justify-content: flex-start;
  flex-direction: row-reverse;
  padding: 0px;
  overflow: hidden;
}

.rc-faq-accordion .MuiAccordionDetails-root {
  color: #fff;
}

.payment-sale-investor .MuiInputBase-root-MuiFilledInput-root {
  background-color: var(--blueGray-900);
}

.payment-enter-investor-ro .MuiInputBase-root-MuiFilledInput-root {
  background-color: var(--blue-600);
}

.payment-enter-investor .MuiInputBase-root.MuiFilledInput-root:hover,
.payment-enter-investor .MuiInputBase-root.MuiFilledInput-root.Mui-focused {
  background-color: #05131b;
}

.payment-enter-investor-ro .MuiFormHelperText-root,
.payment-sale-investor .MuiFormHelperText-root {
  color: var(--white-color);
}

.payment-enter-investor input:-webkit-autofill,
.payment-enter-investor input:-webkit-autofill:hover,
.payment-enter-investor input:-webkit-autofill:focus,
.payment-enter-investor input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
}

.rc-payment-tab-wrapper span.MuiTabs-indicator {
  background-color: transparent;
  display: flex;
  justify-content: center;
  border-radius: 12px 12px 0px 0px;
  /* 
    & .MuiTabs-indicatorSpan {
        height: 10px;
        border-radius: 8px 8px 0px 0px;
        max-width: 50px;
        width: 100%;
        background-color: var(--rc-tertiary-button-color);
    } */
}

.rc-payment-tab-wrapper span.MuiTabs-indicator .MuiTabs-indicatorSpan {
  height: 10px;
  border-radius: 8px 8px 0px 0px;
  max-width: 50px;
  width: 100%;
  background-color: var(--yellow-50);
}


.rc-payment-tab-wrapper .MuiTabs-flexContainer {
  gap: 8px;
}

/* Payment Tab design */

.rc-payment-tab.MuiTab-root {
  color: var(--white-color);
  text-transform: none;
  border-radius: 12px;
  background-color: black;
  /* 
    &.Mui-selected {
        color: var(--white-color);
        background-color: var(--blue-600);
    }

    ; */
}

.rc-payment-tab.MuiTab-root.Mui-selected {
  color: var(--yellow-200);
  background-color: var(--blue-600);
}

.rc-payment-tab.MuiTab-root .MuiTabs-indicator {
  background-color: var(--yellow-50);
}

/* Left Panel Css */
.rc-asset-option-img-wrapper.MuiBox-root {
  width: 100%;
}

.rc-asset-main-img.MuiBox-root {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.rc-asset-option-img.MuiBox-root {
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  border: 2px solid transparent;
}

.rc-asset-option-img.MuiBox-root:hover {
  border: 2px solid white;
}

.rc-back-btn.MuiIconButton-root {
  margin-bottom: 12px;
  border-radius: 12px;
  color: var(--white-color);
}

.rc-icon-fab.MuiIconButton-root {
  background-color: var(--blue-50);
  border-radius: 12px;
  color: var(--white-color);
}

.rc-icon-fab-secondary.MuiIconButton-root {
  background-color: var(--blue-500);
  border-radius: 12px;
  color: var(--blue-50);
  transition: ease-in-out 0.2s;
}

.rc-icon-fab-secondary.MuiIconButton-root:hover {
  background-color: var(--blue-600);
  /* color: var(--blue-50); */
}

.rc-icon-fab-secondary-box .rc-icon-fab-secondary.MuiIconButton-root {
  background-color: var(--blue-900);
  border-radius: 12px;
  color: var(--blueGray-400);
  transition: ease-in-out 0.2s;
}

.rc-icon-fab-secondary-box .rc-icon-fab-secondary.MuiIconButton-root:hover {
  background-color: var(--blue-50);
  color: var(--white-color);
}

.rc-tabitem-wrapper.MuiTabs-root span {
  display: none;
}

.rc-tabitem-wrapper.MuiTabs-root {
  padding-right: 5px;
}

.rc-tabitem.MuiTab-root {
  min-height: 54px;
  justify-content: space-between;
  white-space: nowrap;
  background-color: transparent;
  color: #ffff;
  font-size: var(--font-base);
  border-radius: 30px;
  text-align: left;
  text-transform: none;
  margin-bottom: 2px;
  transition: all 0.2s ease-in-out;
}

.rc-tabitem.Mui-selected {
  color: #ffff !important;
  background-color: #205a7e;
}

.rc-tabitem>svg.MuiSvgIcon-root {
  display: none;
}

.rc-tabitem.Mui-selected>svg.MuiSvgIcon-root {
  display: inline-block;
}

.rc-project-details-right.MuiBox-root {
  background-color: var(--blue-900);
  padding: 16px;
  border-radius: 12px;
}

.rc-invest-btn.MuiButton-root,
.rc-invest-btn-sold.MuiButton-root {
  width: 100%;
  padding: 10px 10px;
  border-radius: 12px;
  margin-bottom: 6px;
  text-transform: none;
  background-color: var(--blue-500);
  font-size: medium;
}

.rc-invest-btn-sold.MuiButton-root,
.rc-invest-btn-sold.MuiButton-root:hover {
  background-color: #dc2626;
  color: var(--black-color);
}

.rc-invest-btn.MuiButton-root:hover {
  background-color: var(--blue-200);
}

.LeftPanelEstate-disc h4 {
  color: #fff;
}

.lpe-Features-list ul li {
  color: white;
}

.PO-price-set-h5 h5 {
  color: white;
}

/* asset details , deal terms and map */

.projectPage-investor-rs .pd-vr-val {
  display: flex;
  justify-content: space-between;
  margin: 16px;
}

.projectPage-investor-rs .pd-df-jc {
  display: flex;
  justify-content: space-between;
}

.projectPage-investor-rs .pd-tranche {
  margin: 16px 0px;
  color: #ffffff;
}

.projectPage-investor-rs .pd-vr-fs13-w {
  color: #ffffff;
  font-size: 13px;
}

.projectPage-investor-rs .pd-vr-fs13-r {
  color: var(--blueGray-400);
  font-size: 13px;
}

.rc-number-increment-wrapper.MuiBox-root {
  background-color: var(--blueGray-900);
  border-radius: 12px;
  padding: 8px;
  color: var(--blueGray-400);
}

/* Parcels Designs  */

.rc-parcel-selected-payment-box.MuiBox-root {
  background-color: #1771bd;
  border-radius: 12px;
  padding: 4px 8px;
  box-sizing: border-box;
}

.leftPanel-details-cm .MuiTypography-body2,
.MuiTypography-h5 {
  color: white;
}

.rc-project-details-left-data.MuiBox-root {
  padding: 10px 10px 0px 0px;
}

.project-details-left-props.MuiBox-root {
  margin: 12px 0px;
}

.rc-nft-prop-card.MuiCard-root {
  background-color: var(--blue-500);
  border-radius: 12px;
  margin-bottom: 12px;
}

.rc-nft-prop-chip.MuiChip-root {
  background-color: var(--yellow-50);
  color: #0f172a;
  font-weight: 500;
  line-height: 1.5rem;
  height: auto;
}

/* UploadedDocs.jsx */
.investor-uploaded-docs {
  padding: 16px;
  background-color: var(--blue-800);
}

.investor-uploaded-docs .TableHeader-ud {
  border-radius: 20px;
}

.investor-uploaded-docs .MuiPaper-root-MuiTableContainer-root {
  box-shadow: none;
}

/* Extra */

.rc-basic-container .rc-filter-chip {
  background-color: var(--blue-500);
  /* color: var(--white-color); */
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
}

.fractional-tabs .transactions-active-button {
  /* background-color: #064165; */
  text-transform: none;
  border-radius: 12px;
  color: #fde0cf !important;
}

.fractional-tabs .transactions-inactive-button {
  /* background-color: #13202D; */
  text-transform: none;
  color: #ffffff !important;
}

.fractional-tabs span.MuiTabs-indicator {
  height: 4px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--yellow-50);
}

.payment-enter-investor .MuiFormControl-root .Mui-disabled {
  background-color: #05131b;
  border-radius: 8px;
}

.payment-enter-investor.loan-type .MuiFormControl-root .MuiFormHelperText-root.Mui-disabled {
  border-radius: 8px;
  background-color: var(--blue-900);
  color: var(--blueGray-400);
}

.emi-date {
  position: relative;
}

.emi-date .extension {
  position: absolute;
  font-size: 10px;
}

.emi-date .extension-base {
  position: absolute;
  font-size: 14px;
  padding-top: 2px;
}

.emi-date .month {
  margin-left: 15px;
}

.custom-tooltip.MuiTooltip-tooltip {
  background: var(--blueGray-500) !important;
  padding: 16px;
  border-radius: 15px;
  margin-top: -32px;
  width: auto !important;
}

.custom-arrow.MuiTooltip-arrow {
  color: var(--blueGray-500) !important;
}

.investor-select-currency-type {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 8px;
}

.investor-select-currency-type .pay-using {
  width: 90px;
  color: var(--blueGray-300);
  line-height: 28px;
  letter-spacing: 0.5px;
  text-wrap: nowrap;
  margin-right: 3px;
}

.investor-select-currency-type .currency-options .MuiFormControlLabel-root .MuiButtonBase-root {
  padding: 0px 4px;
}

.investor-select-currency-type .currency-options .MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
  color: var(--blueGray-300);
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 1024px) {

  .rc-invest-btn.MuiButton-root,
  .rc-invest-btn-sold.MuiButton-root {
    padding: 10px;
  }
}

@media only screen and (max-width: 767px) {

  .rc-invest-btn.MuiButton-root,
  .rc-invest-btn-sold.MuiButton-root {
    padding: 10px 10px;
    text-align: left;
    line-height: 22px;
  }

  .rc-terms-checkbox.MuiFormControlLabel-root {
    padding: 10px;
  }
}