.transactions-list-head-inside {
  color: #13202d;
}

.transactions-list-head-inside .tab-div {
  border-bottom: 1px solid #95959561;
}

.transactions-list-head .transactions-active-button {
  /* background-color: #064165; */
  text-transform: none;
  border-radius: 12px;
  color: #fde0cf !important;
}

.transactions-list-head .transactions-inactive-button {
  /* background-color: #13202D; */
  text-transform: none;
  color: #ffffff !important;
}

.transactions-list-head span.MuiTabs-indicator {
  height: 4px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--yellow-50);
}

.investor-transaction-list-head {
  margin-top: 16px;
}

.currency-align {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

/* .transactions-list-card {
  background-color: #13202D !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
} */

.TableContainer.MuiTableContainer-root {
  padding: 10px;
  padding-top: 24px;
  border-radius: 16px;
  background-color: var(--blue-900) !important;
}

.FirstTableContainer.MuiTableContainer-root {
  padding: 10px;
  /* padding-top: 24px; */
  border-radius: 16px;
  background-color: var(--blue-900) !important;
}

.minimum-prepayment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.minimum-prepayment p {
  font-size: 18px;
  margin-right: 10px;
}

.minimum-prepayment .calculated-amount-min-text {
  font-size: 12px;
  margin: 2px 8px;
}

.loan-prepayment.error-text {
  font-size: 14px;
  color: #d32f2f;
  margin-top: -6px;
}

.minimum-prepayment-text.MuiFormControl-root input.MuiInputBase-input {
  color: var(--white-color);
}

.EditCancelOfferModal-field .MuiFormControl-root input.MuiInputBase-input {
  font-size: 12px;
}

/* button css */
.status-button,
.status-button-action {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 164px;
  height: 40px;
  background: #06487e !important;
  color: #ffffff !important;
  border-radius: 40px !important;
  text-transform: none !important;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.status-button.disabled,
.status-button-action.disabled {
  background: #334155 !important;
  color: #6a91a9 !important;
}

.status-button-action {
  color: var(--blueGray-500) !important;
  background: #334155 !important;
}

.payment-type-color {
  color: #dac88a !important;
}

.category-button {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px;
  height: 40px;
  border: 2px solid #6a91a9 !important;
  border-radius: 40px !important;
  text-transform: none !important;
  color: #ffffff !important;
  margin-top: 12px;
}

.tabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 200px;
  height: 48px;
  color: #ffffff;
  text-transform: none !important;
  border-radius: 12px;
}

.DateRange .DatePicker {
  width: 145px;
  margin-left: 12px;
}

.DatePicker .MuiFormControl-root .MuiFormLabel-root {
  color: var(--white-color);
  font-weight: 500;
}

.DatePicker .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  color: var(--white-color) !important;
}

.DatePicker .MuiFormControl-root .MuiInputBase-root {
  height: 40px;
}

.DatePicker .MuiFormControl-root input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: var(--white-color);
  border-radius: 3px;
}

.DatePicker .MuiFormControl-root input[type="date"]::-webkit-datetime-edit {
  color: white;
}

.table-project-name {
  margin: 0px 20px 20px;
}

.table-project-name p {
  color: white;
  font-size: 18px;
}

.document-main-div {
  background: var(--blue-900) !important;
  border-radius: 12px;
  padding: 12px;
  margin-top: 18px;
}

.my-document-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.my-document-container .filters-group-wrap .filterSearch-form {
  width: 100%;
  margin-top: 20px;
}

.service-pro-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sp-sortby {
  color: #fff;
}

.mydocumentmain {
  margin-top: 3px;
}

@media only screen and (max-width: 767px) {
  .transactions-list-head-inside .filterSearch-form {
    width: 100%;
    margin-top: 20px;
  }

  .transactions-list-head-inside .MuiTabs-scroller.MuiTabs-fixed {
    overflow: auto !important;
  }

  .fp-right .select-group {
    width: 100%;
    margin-left: 0px;
  }

  .transactions-list-head-inside .MuiTabs-scroller.MuiTabs-fixed {
    overflow: auto !important;
  }

  .service-pro-tab {
    flex-direction: column;
  }

  .my-document-container .ctSearch {
    margin-top: 15px;
  }

  .transactions-list-head-inside .sort-by-container {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .transactions-list-head-inside .MuiTabs-scroller.MuiTabs-fixed {
    overflow: auto !important;
  }

  .transactions-list-head-inside .tabs {
    width: 100px;
  }
}