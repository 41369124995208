.info-card-box.MuiPaper-root {
  background-color: #ffede2;
  padding: 16px;
  border-radius: 16px;
  height: 100%;
}

.info-card-box .monthly-payment {
  word-wrap: break-word;
  margin-top: 5px;
}

.info-card-box .monthly-payment {
  word-wrap: break-word;
  margin-top: 5px;
}

/* Add this to your stylesheet or in the component style */
.share-popover-button .share-popover {
  background-color: var(--white-color);
  border: 1px solid darkgray;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.share-popover-button {
  cursor: pointer;
}

.desc-tab-max-width .des-tab-title {
  display: block;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pc-chip-capitalinfo.rejected {
  background-color: var(--red-500);
}

.amortization {
  background-color: var(--blueGray-900);
  border-radius: 16px;
  padding: 12px;
  /* max-width: 500px; */
}

.amortization .TableHeader tr th {
  padding: 6px;
  font-size: 12px;
}

.amortization .MuiTableBody-root tr td {
  padding: 10px;
  font-size: 12px;
}

.amortization .MuiTabs-root {
  overflow-x: auto;
}

.amortization .MuiTabs-root .MuiButtonBase-root.am-active {
  background-color: (--blueGray-900);
  text-transform: none;
  color: #fde0cf;
  max-width: 180px;
  min-width: 150px;
}

.amortization .MuiTabs-root button.MuiTab-root.Mui-selected {
  background-color: var(--blueGray-900);
}

.amortization .MuiTabs-root .MuiButtonBase-root.am-inactive {
  background-color: var(--blueGray-900);
  text-transform: none;
  color: var(--blueGray-400);
  max-width: 180px;
  min-width: 150px;
}

.amortization .MuiTabs-root button.MuiTab-root span.MuiTouchRipple-root {
  color: #ede4c5;
}

.amortization .MuiTabs-root button.MuiTab-root {
  color: var(--white-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
  padding: 14px;
  height: 45px;
  min-height: 45px;
  border-radius: 100px;
  background: transparent;
  justify-content: space-between;
  text-transform: capitalize;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.amortization .MuiTabs-root .MuiTabs-indicator {
  height: 2px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--yellow-50);
  display: block;
}

.amortization .MuiTabs-scroller {
  overflow: auto !important;
  scrollbar-width: thin;
  scrollbar-color: #ede4c5 transparent;
}

.amortization .MuiTabs-scroller::-webkit-scrollbar {
  width: 3px;
  /* Width of the scrollbar */
}

.amortization .MuiTabs-scroller::-webkit-scrollbar-thumb {
  background-color: #ede4c5;
  /* Thumb color */
  border-radius: 3px;
  /* Rounded corners */
}

.amortization .MuiTabs-scroller::-webkit-scrollbar-track {
  background-color: transparent;
  /* Track color */
}