.pf-page .pf-name-id {
  padding: 12px 16px 12px 0px;
}

.pf-page .pf-name-id {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 72px;
}

.pf-page .pf-id {
  height: 20px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-base);
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #94a3b8;
}

.pf-page .pf-name {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.5;
}

.pf-page .pfInfo {
  align-items: flex-start;
  padding: 2px 16px 16px;
  background: var(--blue-900);
  border-radius: 12px;
}

.pf-page .pfInfo-flex {
  display: flex;
  justify-content: space-between;
}

.pf-page .pfInfo .pfIf {
  height: 24px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 16px 0px;
  color: var(--white-color);
}

.pf-page .pfInfo .pfSa {
  height: 16px;
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: 16px;
  text-align: right;
  letter-spacing: 0.5px;
  color: #93c5fd;
}

.pf-page .pfInfo .pfIf-card {
  padding: 16px;
  height: 146px;
  width: 100%;
  background: #ffede2;
  border-radius: 12px;
}

.pfIf-card .pfIf-card-icon-box {
  display: flex;
  justify-content: flex-end;
}

.pfIf-card .pfIf-card-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background: #dcae92;
  border-radius: 64px;
  color: #ffede2;
  margin-right: 0px;
}

.pfIf-card .pfIf-card-title {
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
}

.pfIf-card .pfIf-card-subtitle {
  font-family: "Rubik", sans-serif;
  /* Added generic font family */
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-base);
  letter-spacing: 0.25px;
  color: #000000;
}

.pf-page .pfAnalytic {
  width: 100%;
  height: 100%;
  background: #064165;
  border-radius: 12px;
}

.pf-page .pfAnalytic .pfMh {
  color: #64748b;
  margin-top: 16px;
}

.pf-page .pfAnal-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.portfolio-slider .slider-container {
  margin-top: 8px;
}

.portfolio-slider .slider-container .slick-slide {
  width: 100%;
}

.portfolio-slider .slider-container .slick-slide>div {
  display: flex !important;
  width: 100% !important;
  gap: 8px;
}

@media only screen and (max-width: 767px) {
  .pfIf-card .pfIf-card-icon {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 599px) {
  .pfIf-card .pfIf-card-title {
    font-size: 16px;
  }
}