.tokenized-assets-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 999;
  transition: all 0.1s ease-in-out;
}

.tokenized-assets-sec {
  height: 100%;
  background: #010509;
  position: relative;
  pointer-events: all;
}

.tokenized-assets-sec::after {
  content: "";
  position: absolute;
  top: -30px;
  bottom: -20px;
  left: -16px;
  right: -2px;
  background: #010509;
}

.tokenized-assets-wrapper .tokenized-assets-card {
  width: 100%;
  background-color: var(--blue-900);
  color: var(--white-color);
  text-transform: capitalize;
  display: block;
  text-align: left;
  border-radius: 24px;
  transition: all 0.1s ease-in-out;
  padding: 2px;
  height: 100%;
  overflow: hidden;
}

.tokenized-assets-wrapper .tokenized-assets-card.active {
  background: var(--golden-gradient);
}

.tokenized-assets-wrapper .tokenized-assets-card .tokenized-assets-card-body {
  padding: 24px;
  border-radius: 24px;
  background-color: var(--blue-900);
  height: 100%;
}

.tokenized-assets-wrapper .tokenized-assets-card:hover,
.tokenized-assets-wrapper
  .tokenized-assets-card:hover
  .tokenized-assets-card-body {
  background-color: #398cd1;
  color: var(--white-color);
}

.tokenized-assets-wrapper .tokenized-assets-card.Mui-selected {
  padding: 4px 24px;
}

.tokenized-assets-wrapper .tokenized-assets-card .tokenized-assets-card-flex {
  display: flex;
  align-items: center;
}

.tokenized-assets-wrapper .tokenized-assets-card .tokenized-assets-image {
  width: 95px;
  height: 95px;
  margin-bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.tokenized-assets-image {
  width: 100%;
  height: 100%;
}

.tokenized-assets-wrapper .tokenized-assets-card .title-wraper {
  margin-bottom: 20px;
}

.tokenized-assets-wrapper .tokenized-assets-card .title-wraper h4 {
  font-size: 24px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.tokenized-assets-wrapper
  .tokenized-assets-card
  .description-wrapper
  .MuiTypography-root {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 400;
}

.tokenized-assets-sec.short-tokenized
  .tokenized-assets-card
  .tokenized-assets-image {
  width: 50px;
  height: 50px;
  transform: translateY(-11px);
}

.tokenized-assets-sec.short-tokenized
  .tokenized-assets-wrapper
  .tokenized-assets-card
  .title-wraper
  h4 {
  transform: translate(50px, -60px);
  font-size: 18px;
}
.tokenized-assets-sec.short-tokenized
  .tokenized-assets-wrapper
  .tokenized-assets-card
  .description-wrapper {
  transform: translate(10px, -50px);
}
@media only screen and (max-width: 1199px) {
  .tokenized-assets-wrapper .tokenized-assets-card .tokenized-assets-card-body {
    padding: 24px 12px;
  }
}
@media only screen and (max-width: 1023px) {
  .tokenized-assets-wrapper .tokenized-assets-card .title-wraper {
    margin-bottom: 20px;
  }
  .tokenized-assets-wrapper
    .tokenized-assets-card
    .description-wrapper
    .MuiTypography-root {
    font-size: 16px;
  }
  .tokenized-assets-sec.short-tokenized
    .tokenized-assets-wrapper
    .tokenized-assets-card
    .title-wraper
    h4 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 900px) {
  .tokenized-assets-sec {
    max-height: 100% !important;
  }
  .tokenized-assets-wrapper {
    z-index: 9;
  }
  .tokenized-assets-wrapper .tokenized-assets-card .tokenized-assets-card-body {
    padding: 24px;
  }
}
